import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"

export const pageQuery = graphql`
 query {
   allStrapiProduct {
    edges {
      node {
        seo {
            metaTitle
            metaDescription
            shareImage {
              url
            }
        },
        title,
        product_categories { 
            topBanner {
                title
            },
            url,
            seo {
                metaDescription
                shareImage {
                    localFile {
                        childImageSharp {
                          gatsbyImageData(width: 3840, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                        }
                      }
                  }
            }
         }
       }
     }
   } 
 }
`

export default function Products({location,data}) {
    const [headerColor,setHeaderColor] = useState("bg-light text-black")
    const node = data.allStrapiProduct.edges[0].node
    return (
        <Layout bgColor={'bg-light'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[193px] pb-[140px] sm:pt-20 sm:pb-10">
                <h1 className="text-[40px] pb-[104px] leading-snug xl:pb-10 sm:pb-5">{node.title}</h1>
                <div className="grid grid-cols-2 gap-[60px] xl:grid-cols-1 sm:gap-8">
                {
                    node.product_categories.map((n,i)=>{
                        return (
                            
                                <div key={i} className="bg-white text-center pb-[58px] px-5">
                                    <figure className="h-[460px] overflow-hidden sm:max-h-[240px] sm:w-full"><GatsbyImage className="w-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.seo.shareImage.localFile)} alt="banner"/></figure>
                                    <h2 className="text-3xl py-2.5 leading-tight">{n.topBanner.title}</h2>
                                    <p className="min-h-[50px] pb-[30px]">{n.seo.metaDescription}</p>
                                    <Button variant="black" className="mx-auto sm:w-full" href={n.url}>查看方案</Button>
                                </div>
                            
                        )
                    })
                }
                </div>
            </div>
        </Layout>
    )
}

